<template lang="pug">

</template>

<style lang="scss">

</style>


<script lang="js">
export default {
	name: 'logs',
	data() {
		return {

		}
	},
	created() {

	},
	mounted() {

	},
	beforeDestroy() {

	},

}
</script>
